import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Skeleton,
  Stack,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TablePagination,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "Constants";
import swal from "sweetalert";
import Auth from "Auth";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function Circular() {
  const navigate = useNavigate();
  const { token } = Auth();

  let config = {
    headers: {
      Authorization: token,
    },
  };

  const rowsPerPageOptions = [5, 10, 25, 50, 100];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);
  const skeletonArray = Array.from({ length: 5 }, (_, index) => (
    <>
      <Skeleton key={index} variant="wave" width={"100%"} height={40} />
      <br />
    </>
  ));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchResolution = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=1st prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchNotice = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=3rd prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchActs = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=2nd prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=4th prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular1 = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=5th prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular2 = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=6th prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular3 = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/godda_get_prc_by_type?type=7th prc`, config);
      setData(res.data.data);
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, text) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });

    if (result.isConfirmed) {
      let url = `delete_prc?id=${id}`;
      try {
        setLoading(true);
        const res = await axios.delete(`${apiUrl}/${url}`, {
          data: { id: id },
          headers: { Authorization: token },
        });
        swal({
          title: "Success!",
          text: "Data deleted!!",
          icon: "success",
          button: "Ok!",
        });

        if (text === "1st PRC") {
          fetchResolution();
        } else if (text === "3rd PRC") {
          fetchNotice();
        } else if (text === "2nd PRC") {
          fetchActs();
        } else if (text === "4th PRC") {
          fetchCircular();
        } else if (text === "5th PRC") {
          fetchCircular1();
        } else if (text === "6th PRC") {
          fetchCircular2();
        } else if (text === "7th PRC") {
          fetchCircular3();
        }
      } catch (error) {
        swal({
          title: "Error!",
          text: "Error deleting data!! " + error,
          icon: "error",
          button: "Ok!",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div>
        <Stack direction={isSmallScreen ? "column" : "row"} spacing={2} alignItems={isSmallScreen ? "stretch" : "center"}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchResolution(), setText("1st PRC");
            }}
            // sx={{ margin: 4, marginBottom: 2 }}
          >
            1st PRC
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchActs(), setText("2nd prc");
            }}
          >
            2nd PRC
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchNotice(), setText("3rd PRC");
            }}
          >
            3rd PRC
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchCircular(), setText("4th PRC");
            }}
          >
            4th PRC
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchCircular1(), setText("5th PRC");
            }}
          >
            5th PRC
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchCircular2(), setText("6th PRC");
            }}
          >
            6th PRC
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              fetchCircular3(), setText("7th PRC");
            }}
          >
            7th PRC
          </Button>
          <Button
            variant="contained"
            color="warning"
            style={{ marginLeft: "auto", marginRight: "5vw" }}
            onClick={() => {
              navigate("/addp");
            }}
          >
            ADD PRC
          </Button>
        </Stack>
        <br />
        <div>
          <h2 className="text-xl font-bold mb-4" style={{ color: "blue" }}>
            {text}
          </h2>
          {loading ? (
            skeletonArray
          ) : (
            <>
              {text !== "" ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ display: "table-header-group" }}>
                      <TableRow>
                        <TableCell>Date </TableCell>
                        <TableCell>Letter No.</TableCell>
                        <TableCell>Applicable Period</TableCell>
                        <TableCell>DA Rate</TableCell>
                        <TableCell>Remarks</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.date}</TableCell>
                          <TableCell>{item.letterNo}</TableCell>
                          <TableCell>{item.applicablePeriod}</TableCell>
                          <TableCell>{item.daRate}</TableCell>
                          <TableCell>{item.remarks}</TableCell>
                          <TableCell>
                            <Button color="warning" variant="contained" onClick={() => handleDelete(item.id, text)}>
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
