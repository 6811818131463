import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Skeleton,
  Stack,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TablePagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "Constants";
import swal from "sweetalert";
import Auth from "Auth";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function Circular() {
  const navigate = useNavigate();
  const { token } = Auth();
  let config = {
    headers: {
      Authorization: token,
    },
  };

  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);

  // Responsive hook
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewPDF = async (pdfLink) => {
    window.open(pdfLink, "_blank");
  };

  const skeletonArray = Array.from({ length: 5 }, (_, index) => (
    <>
      <Skeleton key={index} variant="wave" width={"100%"} height={40} />
      <br />
    </>
  ));

  const fetchData = async (url, fetchFunc) => {
    try {
      setLoading(true);
      const res = await axios.get(url, config);
      if (res.data["body-json"]["statusCode"] !== 200 || res.data["body-json"]["statusCode"] === undefined) {
        swal({
          title: "Error!",
          text: "Error fetching data!!",
          icon: "error",
          button: "Ok!",
        });
      }
      if (res.data["body-json"]["body"] === undefined || res.data["body-json"]["body"].length === 0) {
        setData([]);
      } else {
        setData(res.data["body-json"]["body"]);
      }
      fetchFunc();
    } catch (error) {
      swal({
        title: "Error!",
        text: "Error fetching data!! " + error,
        icon: "error",
        button: "Aww No!",
      });
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular = async () => fetchData(`${apiUrl}/jmoa_circular_all_data`, setText.bind(null, "Circular"));
  const fetchResolution = async () => fetchData(`${apiUrl}/jmoa_resolution_all_data`, setText.bind(null, "Acts & Rules"));
  const fetchNotice = async () => fetchData(`${apiUrl}/jmoa_notice_all_data`, setText.bind(null, "Publication"));
  const fetchActs = async () => fetchData(`${apiUrl}/get_all_documents`, setText.bind(null, "JSNGEF Documents"));

  const handleDelete = async (id, text) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (result.isConfirmed) {
      let url = "";
      if (text === "Acts & Rules") url = "jmoa_resolution_delete";
      else if (text === "Notice") url = "jmoa_notice_delete";
      else if (text === "JSNGEF Documents") url = "documents_delete";
      else if (text === "Circular") url = "jmoa_circular_delete";

      try {
        setLoading(true);
        const res = await axios.delete(`${apiUrl}/${url}`, {
          data: { id: id },
          headers: { Authorization: token },
        });
        if (res.data["body-json"]["statusCode"] !== 200 || res.data["body-json"]["statusCode"] === undefined) {
          swal({
            title: "Error!",
            text: "Error deleting data!!",
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Success!",
            text: "Data deleted!!",
            icon: "success",
            button: "Ok!",
          });
        }

        if (text === "Acts & Rules") fetchResolution();
        else if (text === "Notice") fetchNotice();
        else if (text === "JSNGEF Documents") fetchActs();
        else if (text === "Circular") fetchCircular();
      } catch (error) {
        swal({
          title: "Error!",
          text: "Error deleting data!! " + error,
          icon: "error",
          button: "Ok!",
        });
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="p-4">
        <Stack direction={isSmallScreen ? "column" : "row"} spacing={2} alignItems={isSmallScreen ? "stretch" : "center"}>
          <Button variant="contained" color="success" onClick={() => fetchResolution()}>
            Acts & Rules
          </Button>
          <Button variant="contained" color="success" onClick={() => fetchCircular()}>
            Circular
          </Button>
          <Button variant="contained" color="success" onClick={() => fetchActs()}>
            JSNGEF Documents
          </Button>
          <Button variant="contained" color="success" onClick={() => fetchNotice()}>
            Publication
          </Button>
          <Button variant="contained" color="warning" onClick={() => navigate("/add")} style={{ marginLeft: "auto" }}>
            Add
          </Button>
        </Stack>

        <h2 className="text-xl font-bold my-4" style={{ color: "blue" }}>
          {text}
        </h2>

        {loading ? (
          skeletonArray
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell>Date </TableCell>
                  <TableCell>Issue No.</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>PDF</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.issueNo}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>
                      <VisibilityIcon style={{ color: "green", cursor: "pointer" }} onClick={() => handleViewPDF(item.link)} />
                    </TableCell>
                    <TableCell>
                      <Button color="warning" variant="contained" onClick={() => handleDelete(item.id, text)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
    </>
  );
}
